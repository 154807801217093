import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// import axios from 'axios'
// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { getUserData } from '@/auth/utils'

export default function useChucNangList() {
  // Use toast
  const toast = useToast()
  const refUserListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'id', label: 'STT', thStyle: { width: '1%' } },
    { key: 'name', sortable: true, label: 'Tên' },
    { key: 'nhom', sortable: true, label: 'Nhóm' },
    { key: 'controller_action', sortable: true, label: 'Controller - Action' },
    { key: 'actions', label: 'Tác vụ', thStyle: { width: '10%' } },
  ]
  const perPage = ref(10)
  const totalUsers = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const roleFilter = ref(null)
  const planFilter = ref(null)
  const statusFilter = ref(null)

  const dataMeta = computed(() => {
    const localItemsCount = refUserListTable.value ? refUserListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalUsers.value,
    }
  })

  const refetchData = () => {
    refUserListTable.value.refresh()
  }

  const currentUser = getUserData()

  watch([currentPage, perPage, searchQuery, roleFilter, planFilter, statusFilter], () => {
    refetchData()
  })
  const fetchUsers = (ctx, callback) => {
    const userData = getUserData()

    store
      .dispatch('app-chuc-nang/fetchData', {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        role: roleFilter.value,
        plan: planFilter.value,
        status: statusFilter.value,
        offset: currentPage.value,
        limit: perPage.value,
        type: 'Thành viên',
        auth: userData.auth_key,
        uid: userData.id,
      })
      .then(response => {
        const { results, rows } = response.data

        callback(results)
        totalUsers.value = rows
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Lỗi lấy dữ liệu',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }
  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const xoaKhachHang = (id, callback, before, after, showToast) => {
    before()
    store
      .dispatch('app-chuc-nang/delete', {
        auth: currentUser.auth_key,
        uid: currentUser.id,
        chuc_nang: id,
      })
      .then(response => {
        callback()
        after()
        showToast(response.data.message, 'success')
      })
      .catch(e => {
        after()
        showToast(e.message, 'danger')
      })
  }

  return {
    fetchUsers,
    xoaKhachHang,
    tableColumns,
    perPage,
    currentPage,
    totalUsers,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refUserListTable,

    refetchData,

    // Extra Filters
    roleFilter,
    planFilter,
    statusFilter,
  }
}
